<template>
    <div class="gallery" :style="style">
        <div class="gallery-container">
            <div v-if="labelPosition === 'before' && data.label" class="gallery-title">
                <h5>{{ data.label }} </h5>
            </div>
            <div
                v-if="$image(data.image, {extension}) && !$image(data.image, {extension}).includes('data:image/')"
                :class="['gallery-image', {'label-after': labelPosition === 'after'}]"
                @click="showModal()"
            >
                <img :class="{ contained }" :src="$image(data.image, {extension})" alt="">
            </div>
            <div v-if="labelPosition === 'after' && data.label" class="gallery-title">
                <h5 class="mb-0">{{ data.label }} </h5>
            </div>
        </div>
        <ImagePopup v-if="withPopup" :id="`${data.id}__${labelPosition}`" :image="data.image" :extension="extension"/>
    </div>
</template>

<script>
import ImagePopup from '@/components/ImagePopup.vue';
    export default {
        name: 'LabelImage',
        components: {
            ImagePopup
        },
        props: {
            data: {
                type: Object,
            },
            labelPosition: {
                type: String,
                default: 'after'
            },
            imgHeight: {
                type: String,
                default: 'auto'
            },
            imgWidth: {
                type: String,
                default: 'auto'
            },
            imgMaxWidth: {
                type: String,
                default: '200px'
            },
            contained: {
                type: Boolean,
                default: false
            },
            extension: {
                type: String,
                default: ''
            },
            withPopup: {
                type: Boolean,
                default: true
            },
        },
        computed: {
            style() {
                return {
                    "--img-height": this.imgHeight,
                    "--img-width": this.imgWidth,
                    "--img-max-width": this.imgMaxWidth,
                }
            },
        },
        methods: {
            showModal() {
                // this.$refs['my-modal'].show()
                if (this.withPopup) {
                    this.$bvModal.show(`image-popup__${this.data.id}__${this.labelPosition}`)
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

    .gallery {

        &-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            .gallery-image {
                cursor: pointer;

                &.label-after {
                    margin-bottom: 10px;
                }

                img {
                    height: var(--img-height);
                    width: var(--img-width);
                    max-width: var(--img-max-width);
                    object-fit: cover;

                    &.contained {
                        border: 1px solid silver;
                    }
                }
            }
            .gallery-title {
                h5 {
                    font-size: 1rem;
                    font-weight: 600;
                }
            }
        }
        
    }
</style>